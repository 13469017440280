import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { rapidForm } from '@truto/truto-link-sdk';
import PropTypes from 'prop-types';
import { Button } from '@mantine/core';
import { toast } from 'react-toastify';
import { useLazyGetTrutoIntegratedAccountTokenQuery } from 'redux/services/truto';
import integrationsApi, {
  useAddSourcesMutation,
  useUpdateTeamsContextMutation
} from 'redux/services/integrations';
import { getTrutoAddSourcePayload } from 'pages/Integrations/utils';
import { RAPID_FORM_MESSAGES } from 'utils/constants';

const RapidFormButton = ({ provider, teamIdFromProps, clickCallback, reAuthCompleted }) => {
  const [getTrutoIntegratedAccountToken, { isLoading: trutoTokenLoading }] =
    useLazyGetTrutoIntegratedAccountTokenQuery();
  const [updateTeamsContext, { isLoading: teamContextLoading }] = useUpdateTeamsContextMutation();
  const dispatch = useDispatch();
  const [addSources, { isLoading }] = useAddSourcesMutation();
  const { user } = useSelector((state) => state.user);
  const { teamId } = useParams();
  const [isDisabled, setIsDisabled] = useState(false);

  const handleRapidForm = async () => {
    setIsDisabled(true);
    try {
      const { data: teamContext } = await updateTeamsContext({ teamId, provider });
      if (!teamContext?.ok) {
        toast.error('Oops, something went wrong. Try again!');
        setIsDisabled(false);
        return;
      }
      const { data } = await getTrutoIntegratedAccountToken(provider);
      const response = await rapidForm(data.token, {
        preventDeselect: {
          collections: {
            message: RAPID_FORM_MESSAGES.PREVENT_DESELECT
          },
          drive_items: {
            message: RAPID_FORM_MESSAGES.PREVENT_DESELECT
          },
          articles: {
            message: RAPID_FORM_MESSAGES.PREVENT_DESELECT
          },
          posts: {
            message: RAPID_FORM_MESSAGES.PREVENT_DESELECT
          }
        }
      });

      if (response.result === 'success') {
        const rapidFormResponse = JSON.parse(response.post_connect_form || {});

        // User needs to select at least one of pages or files
        // This can only happen for SharePoint as we cannot make the fields required in RapidForm
        // For all others, collections will be required to submit the rapidform so it cannot be empty
        if (!rapidFormResponse.collections && !rapidFormResponse.drive_items) {
          toast.error('Please select at least one source to add');
          setIsDisabled(false);
          return;
        }

        const payload = getTrutoAddSourcePayload({
          response: rapidFormResponse,
          provider,
          user,
          teamId
        });

        if (payload.length) {
          const { data } = await addSources({
            body: { documents: payload },
            provider,
            params: { teamId: teamId || teamIdFromProps }
          });
          if (data?.ok) {
            clickCallback?.();
          }
          dispatch(integrationsApi.util.invalidateTags(['SourcesByProvider']));
        } else {
          toast.error('Please select at least one source to add');
        }
      }
      setIsDisabled(false);
    } catch (error) {
      setIsDisabled(false);
      if (error === 'closed') {
        toast.error('The form was closed before saving the sources');
      } else {
        toast.error('Oops, something went wrong. Try again!');
      }
    }
  };

  return (
    <Button
      className='primaryBtn'
      onClick={(e) => {
        e.stopPropagation();
        handleRapidForm();
      }}
      ml={20}
      loading={isLoading || trutoTokenLoading || teamContextLoading || reAuthCompleted}
      disabled={isDisabled}
    >
      Add source
    </Button>
  );
};

RapidFormButton.defaultProps = {
  teamIdFromProps: '',
  clickCallback: () => null
};

RapidFormButton.propTypes = {
  provider: PropTypes.string.isRequired,
  teamIdFromProps: PropTypes.string,
  clickCallback: PropTypes.func,
  reAuthCompleted: PropTypes.bool
};

export default RapidFormButton;
