import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Button, Flex } from '@mantine/core';
import PropTypes from 'prop-types';
import AddGoogleSources from '../../Google/AddGoogleSources';
import AddSlackSources from '../../Slack/AddSlackSources';
import AddCustomSources from '../../Custom/AddCustomSources';
import RapidFormButton from '../../OtherIntegrations/RapidForm';
import AddAPIDeckSources from '../../OtherIntegrations/AddSourcesButton';
import AddSourceButtonWithModal from '../../OtherIntegrations/AddSourceButtonWithModal';
import ProviderConnectionStatusModal from '../../OtherIntegrations/ProviderConnectionStatusModal/index.jsx';
import {
  TRUTO_CONNECTIONS_WITH_ADD_SOURCE,
  PROVIDERS,
  CONNECTION_SYNC_STATUS
} from 'utils/constants';
import dashboardStyles from '../../connector.module.scss';

export const AddSourceButtonUI = ({ isDisabled, handleAddSource, isDrivesCta, isLoading }) => {
  const { provider } = useParams();
  const title = isDrivesCta
    ? 'Select Drive to Train'
    : provider === PROVIDERS.GOOGLE
    ? 'Add from Google Drive'
    : 'Add source';

  return (
    <Button
      className={dashboardStyles.addSourceBtn}
      disabled={isDisabled}
      onClick={handleAddSource}
      loading={isLoading}
    >
      {title}
    </Button>
  );
};

AddSourceButtonUI.defaultProps = {
  isDisabled: false,
  isLoading: false,
  isDrivesCta: false
};

AddSourceButtonUI.propTypes = {
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  isDrivesCta: PropTypes.bool,
  handleAddSource: PropTypes.func.isRequired
};

const AddSourceButton = ({
  showUpdateModal,
  providerFromProp,
  teamIdFromProps,
  clickCallback,
  reAuthCompleted
}) => {
  const { provider: providerFromParam } = useParams();
  const provider = providerFromParam || providerFromProp;
  const { user } = useSelector((state) => state.user);
  const isProviderConnected = !!user?.connections?.find((conn) => provider === conn.provider);
  const providerConnectionStatus = user?.connections?.find(
    (conn) => provider === conn.provider
  )?.syncStatus;
  const showButton = (button) => {
    return isProviderConnected ? button : <AddSourceButtonWithModal provider={provider} />;
  };

  const showDefaultCta = () => {
    if (isProviderConnected) {
      //user connected the connection
      if (
        [CONNECTION_SYNC_STATUS.FAILED, CONNECTION_SYNC_STATUS.IN_PROGRESS].includes(
          providerConnectionStatus
        )
      ) {
        return (
          <ProviderConnectionStatusModal provider={provider} status={providerConnectionStatus} />
        );
      }
      if (TRUTO_CONNECTIONS_WITH_ADD_SOURCE.includes(provider)) {
        //return rapid form cta
        return (
          <RapidFormButton
            teamIdFromProps={teamIdFromProps}
            provider={provider}
            clickCallback={clickCallback}
            reAuthCompleted={reAuthCompleted}
          />
        );
      } else {
        //return integrate cta
        return (
          <Flex ml={20}>
            <AddAPIDeckSources
              teamIdFromProps={teamIdFromProps}
              provider={provider}
              clickCallback={clickCallback}
            />
          </Flex>
        );
      }
    } else {
      //return add source with modal
      return <AddSourceButtonWithModal provider={provider} />;
    }
  };

  switch (provider || providerFromProp) {
    case PROVIDERS.GOOGLE:
      return showButton(
        <AddGoogleSources clickCallback={clickCallback} teamIdFromProps={teamIdFromProps} />
      );
    case PROVIDERS.SLACK:
      return showButton(
        <AddSlackSources
          showUpdateModal={showUpdateModal}
          teamIdFromProps={teamIdFromProps}
          clickCallback={clickCallback}
        />
      );
    case PROVIDERS.CUSTOM_SOURCES:
      return showButton(
        <AddCustomSources teamIdFromProps={teamIdFromProps} clickCallback={clickCallback} />
      );
    default:
      return showDefaultCta();
  }
};

AddSourceButton.defaultProps = {
  showUpdateModal: false,
  providerFromProp: '',
  teamIdFromProps: '',
  clickCallback: () => null
};

AddSourceButton.propTypes = {
  showUpdateModal: PropTypes.bool,
  providerFromProp: PropTypes.string,
  teamIdFromProps: PropTypes.string,
  clickCallback: PropTypes.func,
  reAuthCompleted: PropTypes.bool
};

export default AddSourceButton;
